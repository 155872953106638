<template>
	<div class="container">
		<div class="nhtop"></div><!-- bj -->
		<div class="zxzf">
			<div class="zftitle">
				<h2>在线支付优势</h2>
				<b><!-- 小横线 --></b>
			</div>
			<div class="nhbox">
				<div class="box1">
					<div class='boxleft'>
						<div class="left">
							<h2>监管合规要求</h2>
							<i class="line1">
								<img src="../../assets/img/line1.png" alt="">
							</i>
							<span>
								基于农业银行的支付结算，防范"二
								清"风险，增加企业资金安全
							</span>
						</div>
						<div class="right">
							<img src="../../assets/img/jianguan.png" alt=""><!-- 监管 -->
						</div>
					</div>
					<div class='boxright'>
						<div class="left">
							<img src="../../assets/img/anquan.png" alt=""><!-- 监管 -->
						</div>
						<div class="right">
							<h2>银行级系统安全</h2>
							<i>
								<img src="../../assets/img/line2.png" alt="">
							</i>
							<span>
								系统部署在银行端，银行级技术及安全支
								持，防止交易数据丢失
							</span>
						</div>
					</div>
				</div>
				<div class="box1">
					<div class="boxleft">
						<div class="left">
							<h2>财务核算效率</h2>
							<i>
								<img src="../../assets/img/line3.png" alt="">
							</i>
							<span>
								提供多维度对账数据，简化
								企业财务对账工作，降低财
								务管理风险，提升核算效率
							</span>
						</div>
						<div class="right">
							<img src="../../assets/img/caiwu.png" alt="">
						</div>
					</div>
					<div class="boxright">
						<div class="left">
							<img src="../../assets/img/jinrong.png" alt="">
						</div>
						<div class="right">
							<h2>金融服务叠加</h2>
							<i>
								<img src="../../assets/img/line4.png" alt="">
							</i>
							<span>
								基于真实的交易背景与信用积累，
								可获得银行综合金融服务机会
							</span>
						</div>
					</div>
				</div>
				<div class="box1">
					<div class="boxleft">
						<div class="left">
							<h2>多场景。多支付模式</h2>
							<i>
								<img src="../../assets/img/line5.png" alt="">
							</i>
							<span>
								支持B2B、B2C等支付模式,支持支付宝、
								微信、银联、储值卡、信用卡等支付方式
							</span>
						</div>
						<div class="right">
							<img src="../../assets/img/changjing.png" alt="">
						</div>
					</div>
					<div class="boxright">
						<div class="left">
							<img src="../../assets/img/qiye.png" alt="">
						</div>
						<div class="right">
							<h2>增加企业公信力</h2>
							<i>
								<img src="../../assets/img/line6.png" alt="">
							</i>
							<span>
								银行参与业务交易结算过程见证，层架交
								易担保支付机制，进一步提升业务公信力
							</span>
						</div>
					</div>
				</div>
				<div class="boxcenter">
					<img src="../../assets/img/nhcenter.png" alt="">
				</div>
			</div>
		</div>
		<div class="bot">
			<div class="bot_box1">
				<div class="bot_box2" v-if="isshow" >
					<span class="text"  @click="handelJupm()">开通在线支付</span>
				</div>
				<div class="bot_box3" v-else>
					<span class="text3"  @click="Jupmbanck()">查看银行账户</span>
				</div>
			</div>
		</div>
		<el-dialog
		  title="检测到您已有账户,可选择一键关联账户"
		  :visible.sync="dialogVisible"
		  width="60%"
		  
		  :before-close="handleClose">
		  <el-form :model="form" ref="form" :rules="rules" :validate-on-rule-change="false">
			  <el-form-item label="名称" >
			  	<el-input disabled v-model="form.AccountName"/>
			  </el-form-item>
			  <el-form-item label="开户行" >
			  	<el-input disabled v-model="form.khh"/>
			  </el-form-item>
			  <el-form-item label="对公账户" >
			  	<el-input disabled v-model="form.Account" />
			  </el-form-item>
			  <el-form-item label="店铺简称" class="abbre" prop="abbre">
			  	<el-input v-model="form.abbre" type='required' class="inputOn" maxlength="6" show-word-limit
			  		@blur="descInput" placeholder="请输入2-6个汉字" />
			  
			  </el-form-item>
			  <el-form-item label="支持开票类型" prop="InvoiceType">
			  	<el-radio v-model="form.InvoiceType" label="1">普通发票</el-radio>
			  	<el-radio v-model="form.InvoiceType" label="2">增值税发票</el-radio>
			  </el-form-item>
			  
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="closeDialog()">关闭</el-button>
		    <el-button type="primary" @click="getAssociation()">关联账户</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import Axios from 'axios'
	export default {
		data() {
			let checkInvoice = (res, val, callback) => {
				
				if (val != '') {
					callback();
				} else {
					return callback(new Error('请选择'));
				}
			}
			return {
				isshow:true,
				form:{
					khh:'',
					InvoiceType:1,
					abbre:''
				},
				merchant_id:'',
				dialogVisible:false,
				rules: {
					abbre: [{
						required: true,
						message: '请输入店铺简称',
						trigger: 'blur|change'
					}],
					InvoiceType: [{
						required: true,
						message: '请选择',
						trigger: 'blur'
					},
					{
						validator: checkInvoice,
						trigger: ['blur', 'change']
					}],
					
				}
			}
		},
		created() {
			 this.$axios.get('vertyCode?seller_id='+this.$store.state.SELLER_ID).then(data => {
					 console.log("data？？",data)
					 if(data.data == null || data.data == '' || data.data == undefined){
						this.getMer()
					 }
					if(data.step == "2"){
						this.isshow = false
					}
				})
		 },
		methods: {
			descInput(e) {
				var reg = /^[\u4E00-\u9FA5]/g;
				const s = e.target;
				s.value = s.value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除除了中文以外的输入的字符
				this.form.abbre = s.value;
				var inputVal = s.value
				console.log("??reg.test(inputVal)",reg.test(inputVal))
				if (inputVal.length < 2) {
					this.$message.error("至少输入2个汉字")
				} else if (reg.test(inputVal) == 'false') {
					this.$message.error("请填写汉字")
				}
			},
			closeDialog(){
				this.dialogVisible = false
			},
			getMer(){
				this.$axios.get('getSellerMerchant?user_id='+this.$store.state.USER_ID).then(data => {
					console.log("??111?",data)
					if(data.data){
						// 显示弹窗 显示关联按钮
						this.dialogVisible = true
						this.form = data.data
						this.form.khh = data.data.BankName+data.data.BankAddress
						this.merchant_id = data.data.id
						this.form.abbre = data.data.abbre
					}
					
				})	
			},
			handelJupm() {
				this.$axios.get('/applyForOpeningTransaction?seller_id='+this.$store.state.SELLER_ID).then(data => {
					if(data.CompanyName == '' || data.CompanyName == null || data.CompanyName == undefined || data.CompanyCertNo=='' || data.CompanyCertNo == null || data.CompanyCertNo == undefined || data.CertificateNo == '' || data.CertificateNo == null || data.CertificateNo == undefined || data.MobilePhone== '' || data.MobilePhone == null || data.MobilePhone== undefined || data.Account== ''||data.Account== null || data.Account== undefined){
						this.$router.push(`/auszahlung/trading`)
					}else{
						this.$router.push(`/auszahlung/check`)
					}
				})
				
			},
			Jupmbanck(){
				this.$router.push(`/user/4`)
			},
			getAssociation(){
				this.$refs.form.validate(valid => {
					if(this.form.InvoiceType && this.form.abbre){
						console.log("forms",this.form)
						Axios.post('connectMerchant',
						this.$qs.stringify({
							seller_id: this.$store.state.USER_ID,
							user_id: this.$store.state.USER_ID,
							merchant_id:Number(this.merchant_id),
							invoice_type:this.form.InvoiceType,
							abbre:this.form.abbre
						})).then(data => {
							if(data.data.code == 0){
								this.$message.success("关联成功");
								this.dialogVisible = false
							}else{
								this.$message.success(data.data.msg);
							}
							
						})	
					}
				})
				
			}
		}
	}
</script>

<style scoped>
	.container{
		width: 1600px;
		background-color: #fff;
	}
	.nhtop{
		height: 521px;
		background: url(../../assets/img/nhBanner.png);
		background-size: 100% 100%;
		position: relative;
	}
	.zxzf{
		
	}
	.zftitle{
		width: 300px;
		margin: 64px auto;
		text-align: center;
	}
	.zftitle h2{
		font-size: 32px;
		color:#333333;
	}
	.zftitle b{
		display: block;
		margin: 8px auto;
		width: 121px;
		height: 6px;
		background-color: #8ab9ff;
		border-radius: 5px;
	}
	.nhbox{
		width: 1200px;
		margin: 0 auto;
		position: relative;
	}
	.box1{
		display: flex;
		justify-content: space-between;
		height: 200px;
		
	}
	.boxleft{
		display: flex;
		justify-content: space-between;
		width: 36%;
	}
	.box1 .boxleft .left{
		
	}
	.box1 .boxleft .left h2{
		color: #333;
		font-size: 24px;
		margin-left: 24px;
	}
	.line1{
		width: 364px;
		height: 10px;
		background: url;
	}
	.left span{
		display: block;
		color: #666;
		margin-left: 24px;
		font-size: 18px;
		padding: 5px 0;
		line-height: 20px;
	}
	.boxright{
		display: flex;
		justify-content: space-between;
		width: 36%;
	}
	.boxright .right{
		
	}
	.box1 .boxright .right h2{
		color: #333;
		font-size: 24px;
		margin-left: 24px;
	}
	.box1 .boxright .right span{
		display: block;
		color: #666;
		margin-left: 24px;
		font-size: 18px;
		line-height: 20px;
	}
	/* 每一个 */
	.box1:nth-child(1) .boxleft,.box1:nth-child(3) .boxleft{
		margin-left: 80px;
	}
	.box1:nth-child(1) .boxright,.box1:nth-child(3) .boxright{
		margin-right: 80px;
	}
	.box1:nth-child(2) .boxleft .right{
		margin-right: 86px;
	}
	.box1:nth-child(2) .boxright .left{
		margin-left: 86px;
	}
	.boxcenter{
		width: 419px;
		height: 432px;
		position: absolute;
		margin: auto;
		left: 0;right: 0;
		top: -230px;bottom: 0;
	}
	.bot{
		position: relative;
		height: 309px;
		background: url(../../assets/img/nhdb.png) bottom center no-repeat;
	}
	.bot .bot_box1{
		/* margin: 0 auto; */
	}
	.bot  .bot_box2 ,.bot .bot_box3{
		width: 247px;
		height:57px;
		margin:auto;
		left: 0;right: 0;
		bottom: 0;top: 0;
		position: absolute;
		background: url(../../assets/img/zfbtn.png) no-repeat ;
		background-size: cover;
	}
	
	.bot  .bot_box2 span ,.bot .bot_box3 span{
		color: #1167D2;
		font-size: 20px;
		display: block;
		height:56px;
		line-height: 56px;
		text-align: center;
	}
	.bot .bot_box3{
		display: none;
	}
</style>
